import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Karjera from '../../pages/karjera';

const PaginatedWpBlogTemplate = ({ data, pageContext }) => {
    return (
        <Karjera
            posts={data.allWpDarboPasiulymas.nodes}
            pageContext={pageContext}
        />
    );
};

PaginatedWpBlogTemplate.propTypes = {
    data: PropTypes.object,
    pageContext: PropTypes.object,
};

export default PaginatedWpBlogTemplate;

export const pageQuery = graphql`
    query ($limit: Int!, $skip: Int!) {
        allWpDarboPasiulymas(
            limit: $limit
            sort: { order: DESC, fields: date }
            skip: $skip
        ) {
            nodes {
                title
                uri
                id
                content
                darboPasiulymai {
                    algosReziai
                    filialas
                    miestas
                    pareigos
                    skyrius
                }
                date(formatString: "MMM DD, YYYY")
                featuredImage {
                    node {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    width: 450
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`;
